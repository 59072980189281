import { Component } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'talkAaya';
  messages: any[] = [];

  constructor(private fns: AngularFireFunctions){

  }

  sendMessage(event: any) {
    const files = !event.files ? [] : event.files.map((file) => {
      return {
        url: file.src,
        type: file.type,
        icon: 'file-text-outline',
      };
    });

    this.messages.push({
      text: event.message,
      date: new Date(),
      reply: true,
      type: files.length ? 'file' : 'text',
      files: files,
      user: {
        name: 'Jonh Doe',
        avatar: 'https://i.gifer.com/no.gif',
      },
    });
    //const botReply = this.chatShowcaseService.reply(event.message);
    //if (botReply) {
      //setTimeout(() => { this.messages.push(botReply) }, 500);
    //}
    const callable = this.fns.httpsCallable('chat');
      let kbList = callable({ 
        projectID:  'aaya-bot4-yeshpb',
        sessionID:'asd',
        query: event.message,
        KBName: 'MTc0ODMwOTI1MDA3MDgwNjUyODA'
      });
      kbList.subscribe(list=>{
        console.log(list);
        this.sendBotMessage(list.fulfillmentText,this.messages);
        
      });
  }


  sendBotMessage(text,messages){
    text.split('.').forEach(function(element) {
      
      var botReply = {
        text: element,
        date: new Date(),
        user: {
          name: 'Aaya',
          avatar: 'https://i.gifer.com/no.gif',
        }
      }
      console.log(botReply);
      console.log(messages);
      setTimeout(() => { messages.push(botReply) }, 500);
    });


    
    
    
  }
}
